import { Icon } from "@blueprintjs/core"
import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { SmallText } from "../../../../../styled/text.styled"
import { IconWrapper, RiskSelectorArea, minusIconCss } from "./SimpleRiskSelectorButton.styled"

interface SimpleRiskSelectorButtonProps {
  icon: React.ReactNode // Accept any React element for the icon.
  label: string // The text label for the button.
  onClick: () => void // The function to call when the button is clicked.
  isActive: boolean // Whether the button is active or not.
}

/**
 * Generic button component that accepts an icon and a label.
 */
export const SimpleRiskSelectorButton = ({
  icon,
  label,
  onClick,
  isActive,
}: SimpleRiskSelectorButtonProps) => {
  const handleOnClick = () => {
    onClick()
    console.log("Button clicked")
  }

  return (
    <FlexContainer flexDirection="column" alignItems="center">
      <SmallText>{label}</SmallText>
      <RiskSelectorArea onClick={handleOnClick} isActive={isActive}>
        <FlexContainer flexDirection="column">
          <IconWrapper margin="6px 0 0 1px">{icon}</IconWrapper>
          {/* minus Icon appears on hover and underlines the CyclicalyIcon */}
          <Icon icon="minus" size={32} css={minusIconCss} />
        </FlexContainer>
      </RiskSelectorArea>
    </FlexContainer>
  )
}
