/** @jsxImportSource @emotion/react */
import { Dispatch, SetStateAction } from "react"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { LargeText, SmallText } from "../../../../styled/text.styled"
import { RiskType } from "../Risks/types"
import { HeaderContainer } from "../components/Header.styled"
import { PortfolioKeyMetrics } from "../components/PortfolioKeyMetrics"
import { DataframeData } from "../types"
import { SectorButtons } from "./components/SectorButtons"
import { RebalancingType } from "./types"
import { RiskTypeInfo } from "../Risks/enums"

interface IRebalancingDataHeaderProps {
  keyMetrics?: DataframeData
  selectedSector: Partial<RiskType>
  setSelectedSector: Dispatch<SetStateAction<RebalancingType>>
  isLoading?: boolean
}

const RebalancingHeader = ({
  keyMetrics,
  selectedSector,
  setSelectedSector,
  isLoading,
}: IRebalancingDataHeaderProps) => {
  const sectorRebalancingInfo = RiskTypeInfo[RiskType.SECTOR_LEVEL_1]
  const SectorIcon = sectorRebalancingInfo.iconComponent

  return (
    <HeaderContainer flexDirection="row-reverse" justifyContent="space-between" gap="20px">
      <PortfolioKeyMetrics keyMetrics={keyMetrics} isLoading={isLoading} />
      <FlexContainer flexDirection="column" justifyContent="space-between">
        <FlexContainer flexDirection="column" alignItems="center">
          <SmallText>Sectors</SmallText>
          <SectorIcon {...sectorRebalancingInfo.iconProps} />
          <SectorButtons selectedSector={selectedSector} setSelectedSector={setSelectedSector} />
        </FlexContainer>

        <LargeText fontWeight="bold">Exposures</LargeText>
      </FlexContainer>
    </HeaderContainer>
  )
}
export default RebalancingHeader
